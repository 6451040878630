import { useEffect, useState } from "react";

import { API_ENDPOINT } from "../constants/constants";

export type Stats = {
  attack: number;
  defense: number;
  agility: number;
};

export type Boosts = {
  [trait_category: string]: {
    [trait_value: string]: Stats;
  };
};

export const useStatsBoosts = () => {
  const [boosts, setBoosts] = useState<Boosts>({});

  useEffect(() => {
    const fetchBoosts = async (): Promise<Boosts> => {
      return await (await fetch(`${API_ENDPOINT}/boostsmw`)).json();
    };
    fetchBoosts()
      .then(setBoosts)
      .catch(() => console.log("Error fetching Boost data"));
  }, []);

  return boosts;
};
