import { useEffect, useState } from "react";

import { API_ENDPOINT } from "../constants/constants";
import { LeaderboardNFT } from "../types/leaderboard";

export const useLeaderboard = () => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardNFT[]>([]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      return await (await fetch(`${API_ENDPOINT}/leaderboard`)).json();
    };
    fetchLeaderboard()
      .then(setLeaderboard)
      .catch(() => console.log("Error fetching leaderboard"));
  }, []);

  return leaderboard;
};
