import "../styles/fightbgs.css";

import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { CenteredContainer } from "../components/CenteredContainer";
import { MenuButton } from "../components/MenuButton";
import { GameScreen } from "../components/Screen";
import { API_ENDPOINT } from "../constants/constants";
import { AnimGIFs } from "../constants/quickFightAnimations";
import { Stats, useStatsBoosts } from "../hooks/useStatsBoosts";
import { QuickFightPlayByPlay } from "../types/fights";
import { NFT } from "../types/nfts";
import { calculateFighterStats } from "../utils/calculateFighterStats";
import { clamp, determineFaction, determineGender } from "../utils/utils";
import { MenuHeader } from "./MainMenu";

/*
Click Fight,
Backend generates play by play,
play out on screen,
increment round,
*/

//Faction Images
const BBLogo = require("../assets/factions/BackyardBrawlers.png");
const DFLogo = require("../assets/factions/DroidFarm.png");
const TCLogo = require("../assets/factions/TigerClaw.png");

//@ts-ignore
import BBBG from "url:../assets/quickfight/BG/BB.mp4";
//@ts-ignore
import DFBG from "url:../assets/quickfight/BG/DF.mp4";
//@ts-ignore
import TCBG from "url:../assets/quickfight/BG/TC.mp4";

const GloveOutline = require("../assets/quickfight/WinGloves/goutline.png");
const GloveFilled = require("../assets/quickfight/WinGloves/gfilled.png");

//@ts-ignore
import UnknownFighter from "url:../assets/gamemaster/unknown.gif";

const BGs = [BBBG, DFBG, TCBG];

// Background Music
const BBMusic = require("url:../assets/quickfight/BGMusic/BBMusic.mp3");
const DFMusic = require("url:../assets/quickfight/BGMusic/DFMusic.mp3");
const TCMusic = require("url:../assets/quickfight/BGMusic/TCMusic.mp3");
const BGMusic = [BBMusic, DFMusic, TCMusic];

export const QuickFightMain = () => {
  const params = useSearchParams();
  const mint = params[0].get("with");
  const oppNum = params[0].get("against");
  const wallet = useWallet();
  const boosts = useStatsBoosts();
  const [errored, setErrored] = useState(false);
  const [simFight, setSimFight] = useState(true);
  const [nft, setNFT] = useState<NFT>();
  const [oppNFT, setOppNFT] = useState<NFT>();
  const [plays, setPlays] = useState<QuickFightPlayByPlay>([]);
  const [round, setRound] = useState(1);
  const [p1HP, setP1HP] = useState(100);
  const [p2HP, setP2HP] = useState(100);
  const [p1RoundWins, setP1Rounds] = useState(0);
  const [p2RoundWins, setP2Rounds] = useState(0);
  const [winner, setWinner] = useState(0);
  const [playsInx, setPlaysInx] = useState(0);
  const p1Faction = useMemo(() => determineFaction(nft), [nft]);
  const p2Faction = useMemo(() => determineFaction(oppNFT), [oppNFT]);
  const p1Stats = useMemo(() => calculateFighterStats(nft, boosts), [nft, boosts]);
  const p2Stats = useMemo(() => calculateFighterStats(oppNFT, boosts), [oppNFT, boosts]);
  const p1Gender = useMemo(() => determineGender(nft?.metadata.name), [nft]);
  const p2Gender = useMemo(() => determineGender(oppNFT?.metadata.name), [oppNFT]);
  const genderCode = useMemo(() => p1Gender! + p2Gender!, [p1Gender, p2Gender]);
  const FightButtonRef = useRef<HTMLButtonElement>(null);
  const FighterGifREF = useRef<HTMLImageElement>(null);
  const EndVideoRef = useRef<HTMLVideoElement>(null);
  const FXRef = useRef<HTMLAudioElement>(null);
  const [showingEndVideo, setShowingEnd] = useState(false);
  const [endGame, setEnd] = useState(false);
  const [hiddenOpp, setHiddenOpp] = useState(true);
  const noStats = oppNum !== null;

  const p1Gloves = useMemo(() => {
    const gloves = [0, 0];
    for (let i = 0; i < p1RoundWins; i++) {
      gloves[i] = 1;
    }
    return gloves.map((v, i) => <GloveImage key={`p1${i}`} src={v === 1 ? GloveFilled : GloveOutline} />);
  }, [p1RoundWins]);
  const p2Gloves = useMemo(() => {
    const gloves = [0, 0];
    for (let i = 0; i < p2RoundWins; i++) {
      gloves[i] = 1;
    }
    return gloves.map((v, i) => <GloveImage key={`p2${i}`} src={v === 1 ? GloveFilled : GloveOutline} />);
  }, [p2RoundWins]);

  const setFighterGif = (src: string) => {
    if (FighterGifREF.current && FighterGifREF.current) {
      FighterGifREF.current.src = src;
    }
  };

  const setFXSrc = (src: string) => {
    if (FXRef.current) {
      FXRef.current.muted = false;
      FXRef.current.src = src;
    }
  };

  const playEndVideo = (whoWon: number, p1, p2) => {
    if (EndVideoRef.current) {
      EndVideoRef.current.src = AnimGIFs[genderCode].end[whoWon === 1 ? "W" : "L"];
    }
    if (p1 < 2 && p2 < 2) {
      setTimeout(() => setShowingEnd(false), 9000);
    } else {
      setTimeout(() => (window.location.href = "/leaderboard"), 10000);
    }
  };

  const handleFight = async () => {
    try {
      if (FightButtonRef.current) {
        FightButtonRef.current.disabled = true;
      }
      setSimFight(true);
      if (winner === 0) {
        const res = await (
          await fetch(`${API_ENDPOINT}/${noStats ? "runbasefight" : "runquickfight"}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              p1: { mint: nft?.mint, hp: 100 },
              p2: { mint: oppNFT?.mint, hp: 100 },
            }),
          })
        ).json();
        setHiddenOpp(false);
        setWinner(res[0]);
        setPlays(res[1]);
      }
    } catch {
      setErrored(true);
    }
  };

  useEffect(() => {
    if (errored) {
      setTimeout(() => (window.location.href = "/"), 2000);
    }
  }, [errored]);

  useEffect(() => {
    const fetchNFTs = async () => {
      const nfts: NFT[] = await (
        await fetch(`${API_ENDPOINT}/getmetadata`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: [mint] }),
        })
      ).json();
      const opponentRes: NFT = await (
        await fetch(`${API_ENDPOINT}/getoppnft`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            number: oppNum ?? Math.floor(Math.random() * 2222),
          }),
        })
      ).json();
      return [nfts[0], opponentRes];
    };
    fetchNFTs()
      .then((nfts) => {
        setNFT(nfts[0]);
        setOppNFT(nfts[1]);
      })
      .catch(() => setErrored(true));
  }, []);

  // Fight GIFs: 8970ms
  useEffect(() => {
    if (simFight && plays.length > 0) {
      console.log("Start fight");
      let currInx = playsInx;
      let p1Wins = p1RoundWins; // Should start at 0
      let p2Wins = p2RoundWins; // This too
      let whowin = 0;
      if (currInx < plays.length) {
        const fxChoice = Math.floor(Math.random() * AnimGIFs[genderCode].fight.length);
        setFighterGif(AnimGIFs[genderCode].fight[fxChoice]);
        setFXSrc(AnimGIFs[genderCode].fx[fxChoice]);
        setTimeout(() => {
          if (FightButtonRef.current) {
            FightButtonRef.current.disabled = false;
          }
          if (FXRef.current) {
            FXRef.current.muted = true;
          }
          setFighterGif(AnimGIFs[genderCode].idle);
          setShowingEnd(true);
        }, 8970);
        let offset = 1500;
        for (let i = currInx; i < plays.length; i++) {
          currInx = i;
          if (plays[i][3] > p1Wins || plays[i][4] > p2Wins) {
            setTimeout(() => {
              setP1Rounds(plays[i][3]);
              setP2Rounds(plays[i][4]);
            }, 8970);
            if (plays[i][3] > p1Wins) {
              whowin = 1;
              p1Wins += 1;
              setTimeout(() => {
                setP2HP(0);
              }, 8300);
            } else {
              whowin = 2;
              p2Wins += 1;
              setTimeout(() => {
                setP1HP(0);
              }, 8300);
            }
            console.log("Breaking...");
            break;
          }
          console.log("Made it past break?");
          console.log(`currInx: ${currInx}, p1: ${plays[i][1]}, p2: ${plays[i][2]}`);
          setTimeout(() => {
            setP1HP(plays[i][1]);
            setP2HP(plays[i][2]);
          }, offset);
          offset = clamp(1500, 8700, offset + Math.floor((Math.random() + 1) * 350));
        }
        setPlaysInx(currInx + 1);

        setTimeout(() => {
          setRound(round + 1);
          playEndVideo(whowin, p1Wins, p2Wins);
          setP1HP(100);
          setP2HP(100);
        }, 9000);
        if (p1Wins >= 2 || p2Wins >= 2) {
          setEnd(true);
        }
      }
      setSimFight(false);
    } else {
      console.log(`Sim ${simFight}, Plays: ${plays.length}`);
    }
  }, [simFight, plays]);

  return (
    <>
      {nft && oppNFT && nft.mint && oppNFT.mint && wallet.publicKey && !showingEndVideo && !errored && (
        <GameScreen>
          <video style={{ zIndex: -1 }} className="roundvideo" src={BGs[p1Faction]} loop autoPlay muted />
          <audio ref={FXRef} autoPlay />
          <audio src={BGMusic[p1Faction]} autoPlay loop />
          <img ref={FighterGifREF} className="fightergif" src={AnimGIFs[genderCode]?.idle} />
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", height: "100%" }}>
            <div>
              <SepPanel>
                <PlayerStatusContainer>
                  <HPBarContainer>
                    <HealthBarMissing />
                    <HealthBarLeft hp={p1HP} maxHP={100} />
                  </HPBarContainer>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <HPText>HP: {p1HP.toFixed(1)}</HPText>
                    <GloveBar>{p1Gloves}</GloveBar>
                  </div>
                </PlayerStatusContainer>
              </SepPanel>
              <SepPanel>
                <div style={{ height: "100%" }}></div>
                <FighterCard>
                  <NameAndImageContainer>
                    <NFTName>{nft.metadata?.name}</NFTName>
                    <NFTImage opp={false} src={nft.metadata?.image} />
                  </NameAndImageContainer>
                  <FighterStatsPanel hidden={false} stats={p1Stats} nft={nft} faction={p1Faction} />
                </FighterCard>
              </SepPanel>
            </div>
            <div>
              <SepPanel>
                <MenuHeader style={{ fontSize: "4rem", marginTop: "20px" }}>{`Round ${round}`}</MenuHeader>
              </SepPanel>
              <SepPanel style={{ flexDirection: "column-reverse" }}>
                {/* Fight Button */}
                <ButtonsPanel>
                  <MenuButton
                    overrides={{ fontSize: "3rem", marginBottom: "50px" }}
                    refVar={FightButtonRef}
                    onClick={handleFight}
                  >
                    FIGHT
                  </MenuButton>
                </ButtonsPanel>
              </SepPanel>
            </div>
            <div>
              <SepPanel>
                <PlayerStatusContainer>
                  <HPBarContainer>
                    <HealthBarMissing />
                    <HealthBarRight hp={p2HP} maxHP={100} />
                  </HPBarContainer>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <GloveBar>{p2Gloves}</GloveBar>
                    <HPText>HP: {p2HP.toFixed(1)}</HPText>
                  </div>
                </PlayerStatusContainer>
              </SepPanel>
              <SepPanel>
                <div style={{ height: "100%" }}></div>
                <FighterCard>
                  <FighterStatsPanel hidden={hiddenOpp && !noStats} stats={p2Stats} nft={oppNFT} faction={p2Faction} />
                  <NameAndImageContainer>
                    <NFTName style={{ textAlign: "right" }}>
                      {hiddenOpp && !noStats ? "The Sol Den #??" : oppNFT.metadata.name}
                    </NFTName>
                    <NFTImage opp={true} src={hiddenOpp && !noStats ? UnknownFighter : oppNFT.metadata.image} />
                  </NameAndImageContainer>
                </FighterCard>
              </SepPanel>
            </div>
          </div>
        </GameScreen>
      )}
      {errored && (
        <GameScreen>
          <MenuHeader>There was an error loading the game. Going back to Fighter Select...</MenuHeader>
        </GameScreen>
      )}
      {!wallet.publicKey && !errored && (
        <GameScreen>
          <CenteredContainer style={{ height: "100vh" }}>
            <WalletMultiButton />
          </CenteredContainer>
        </GameScreen>
      )}
      {showingEndVideo && !errored && wallet.publicKey && (
        <GameScreen>
          <video className="roundvideo" ref={EndVideoRef} autoPlay />
          <CenteredContainer style={{ height: "100vh" }}>
            {endGame && <WinText>YOU {winner === 1 ? "WIN" : "LOSE"}</WinText>}
          </CenteredContainer>
        </GameScreen>
      )}
    </>
  );
};

const FighterStatsPanel = (props: { nft: NFT; faction: number; stats: Stats; hidden: boolean }) => {
  const determineLogo = () => {
    switch (props.faction) {
      case 0:
        return BBLogo;
      case 1:
        return DFLogo;
      case 2:
        return TCLogo;
    }
  };

  const wins = props.nft?.metadata?.attributes?.find((a) => a.trait_type === "W")?.value;
  const losses = props.nft?.metadata?.attributes?.find((a) => a.trait_type === "L")?.value;

  return (
    <StatPanel>
      <StatNumber color="#ff914d">Damage: {props.hidden ? "??" : props.stats.attack}</StatNumber>
      <StatNumber color="#38B6FF">Defense: {props.hidden ? "??" : props.stats.defense}</StatNumber>
      <StatNumber color="#C9E265">Agility: {props.hidden ? "??" : props.stats.agility}%</StatNumber>
      <FactionImg src={determineLogo()} />
      <StatNumber color="white">
        {props.hidden ? "??" : wins} Wins - {props.hidden ? "??" : losses} Losses
      </StatNumber>
    </StatPanel>
  );
};

const GloveBar = styled.div`
  display: flex;
  gap: 5px;
`;

const GloveImage = styled.img`
  width: 60px;
`;

const WinText = styled.span`
  font-family: CaptureIt;
  font-size: 10rem;
  color: white;
  z-index: 1;
`;

const HPText = styled.span`
  font-family: Lovelo;
  font-size: 2rem;
  color: #cc6b2f;
  margin: 0px 20px 20px 20px;
`;

const PlayerStatusContainer = styled.div`
  height: 250px;
  width: 100%;
  margin: 50px 20px 20px 20px;
`;

const HPBarContainer = styled.div`
  height: 25px;
  position: relative;
  border-radius: 2px;
  margin: 0px 20px 20px 20px;
`;

const HealthBarMissing = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  position: absolute;
`;

const HealthBarLeft = styled.div`
  visibility: ${(props: { hp: number; maxHP: number }) => (props.hp <= 0 ? "hidden" : "visible")};
  width: ${(props) => (props.hp / props.maxHP) * 100}%;
  position: absolute;
  background-color: #cc6b2f;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 2px;
  transition: 500ms;
`;

const HealthBarRight = styled.div`
  visibility: ${(props: { hp: number; maxHP: number }) => (props.hp <= 0 ? "hidden" : "visible")};
  width: ${(props) => (props.hp / props.maxHP) * 100}%;
  position: absolute;
  background-color: #cc6b2f;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 2px;
  transition: 500ms;
`;

const FactionImg = styled.img`
  max-width: 100px;
`;

const StatNumber = styled.span`
  margin-top: 10px;
  font-family: Lovelo;
  font-size: 1.5rem;
  color: ${(props) => props.color};
`;

const NFTImage = styled.img`
  border: 5px ${(props: { opp: boolean }) => (props.opp ? "black" : "white")} solid;
  border-radius: 5px;
  width: 90%;
`;

const NFTName = styled.span`
  font-size: 1.2rem;
  font-family: CaptureIt;
  margin: 10px 10px;
  color: white;
  width: 100%;
`;

const NameAndImageContainer = styled.div`
  padding: 5px;
`;

const StatPanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FighterCard = styled.div`
  background-color: #ff914d69;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin-bottom: 40px;
  border-radius: 5px;
  justify-self: flex-end;
  z-index: 1;
  border: 5px solid #b34400;
  border-opacity: 0.7;
`;

const ButtonsPanel = styled.div`
  bottom: 0;
  display: flex;
`;

const SepPanel = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
