import React from "react";
import styled from "styled-components";
//@ts-ignore
import BGVideo from "url:../assets/videos/mainmenu.mp4";

import { MenuButton } from "../components/MenuButton";
import { GameScreen } from "../components/Screen";
import { BREAKPOINT } from "../constants/constants";

const DFMusic = require("url:../assets/quickfight/BGMusic/DFMusic.mp3");

export default function MainMenu() {
  return (
    <GameScreen>
      <audio src={DFMusic} autoPlay loop />
      <video className="bg-video" src={BGVideo} autoPlay={true} loop={true} muted={true} />
      <Menu>
        <MenuHeader>THE SOL DEN</MenuHeader>
        <MenuButton onClick={() => (window.location.href = "/quickfight")}>QUICK FIGHT</MenuButton>
        <MenuButton onClick={() => (window.location.href = "/leaderboard")}>LEADERBOARD</MenuButton>
        <MenuButton onClick={() => (window.location.href = "/onevone")}>1 V 1</MenuButton>
        <MenuButton disabled>BOSS FIGHTS</MenuButton>
        <TweetButton
          href="http://twitter.com/intent/tweet?text=I%20just%20played%20the%20Sol%20Den%20game!%20You%20have%20to%20check%20this%20out!%20%40TheSolDenNFTs&url=https%3A%2F%2Ffight.solden.io"
          target="_blank"
        >
          TWEET
        </TweetButton>
      </Menu>
    </GameScreen>
  );
}

const TweetButton = styled.a`
  margin-top: 80px;
  border: none;
  background: none;
  font-size: 1rem;
  font-family: CooperHewittHeavy;
  letter-spacing: 3px;
  /* color: var(--menuTextColor); */
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
  color: var(--tweetTextColor);
  transition: 0.8s ease;
  &:hover {
    font-size: 1.25rem;
    transition: 0.8s ease;
    cursor: pointer;
  }
`;

const Menu = styled.div`
  margin-right: 50px;
  float: right;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 25px;
  ${BREAKPOINT} {
    align-items: center;
    margin: 0;
  }
`;

export const MenuHeader = styled.span`
  border: none;
  background: none;
  font-size: 7rem;
  font-family: CaptureIt;
  letter-spacing: 3px;
  color: white;
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
  padding-bottom: 50px;
  ${BREAKPOINT} {
    font-size: 4rem;
  }
`;
