import type { Boosts, Stats } from "../hooks/useStatsBoosts";
import type { NFT } from "../types/nfts";

export const calculateFighterStats = (nft?: NFT, boosts?: Boosts) => {
  let dmg = 15;
  let def = 2;
  let agil = 10;
  if (nft && boosts) {
    for (const attr of nft.metadata.attributes) {
      try {
        const boostStats = boosts[attr.trait_type][attr.value];
        if (boostStats) {
          dmg += boostStats.attack;
          def += boostStats.defense;
          agil += boostStats.agility;
        }
      } catch (e) {
        continue;
      }
    }
  }
  return { attack: dmg, defense: def, agility: agil } as Stats;
};
