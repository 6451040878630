import React from "react";
import styled from "styled-components";

export const CenteredContainer = (props: { className?: string; style?: object; children: React.ReactNode }) => {
  return (
    <Cont style={props.style ? props.style : {}} className={props.className ? props.className : ""}>
      {props.children}
    </Cont>
  );
};

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
