import React from "react";
import styled from "styled-components";

export const GameScreen = (props: { bg?: string; children: React.ReactNode }) => {
  return <Screen className={`${props.bg ? props.bg + " covered-bg" : ""}`}>{props.children}</Screen>;
};

const Screen = styled.div`
  height: 100vh;
`;
