import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { DisplayingOverlayContext, OpponentSelectionContext } from "../pages/OneVOneSelect";

export const NumberInputOverlay = () => {
  const [, setSelection] = useContext(OpponentSelectionContext)!;
  const [, setDisplaying] = useContext(DisplayingOverlayContext)!;
  const [validInput, setValidInput] = useState(true);

  const boxRef = useRef<HTMLInputElement>(null);

  const validateInput = () => {
    let value = "";
    if (boxRef.current) {
      value = boxRef.current.value;
    }
    const parsed = parseInt(value);
    if (!isNaN(parsed) && parsed >= 0 && parsed <= 2221) {
      return true;
    }
    setValidInput(false);
    return false;
  };

  useEffect(() => {
    if (!validInput) {
      setTimeout(() => setValidInput(true), 4000);
    }
  }, [validInput]);

  return (
    <Overlay>
      <InputContainer>
        <MessageText>Enter the number of the fighter you&apos;d like to challenge</MessageText>
        <InputBox type="text" pattern="\\d*" maxLength={4} placeholder="i.e. 420" ref={boxRef} />
        {!validInput && <MessageText color="#ff0000">Invalid Number!</MessageText>}
        <ButtonContainer>
          <InputButton
            onClick={() => {
              if (validateInput()) {
                setSelection(parseInt(boxRef.current!.value));
              }
            }}
          >
            Submit
          </InputButton>
          <InputButton
            onClick={() => {
              setDisplaying(false);
            }}
          >
            Cancel
          </InputButton>
        </ButtonContainer>
      </InputContainer>
    </Overlay>
  );
};

const componentSpacing = "20px";

const MessageText = styled.span`
  margin-top: ${componentSpacing};
  color: white;
  font-family: Lovelo;
  font-size: 1.3rem;
`;

const InputBox = styled.input`
  margin-top: ${componentSpacing};
  border: none;
  padding: 5px;
  font-size: 1.5rem;
  text-align: center;
  background-color: #2b1f14;
  color: white;
`;

const ButtonContainer = styled.div`
  margin-top: ${componentSpacing};
  display: flex;
  width: 30%;
  justify-content: space-between;
`;

const InputButton = styled.button`
  border: none;
  background-color: #2b1f14;
  color: white;
  padding: 10px 10px;
  font-size: 1rem;
  font-family: Lovelo;
  transition: 100ms;
  border-radius: 3px;
  &:hover {
    padding: 15px 20px;
  }
`;

const InputContainer = styled.div`
  max-width: 1000px;
  min-height: 100px;
  padding: 10px;
  background-color: #ff914d69;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
`;

const Overlay = styled.div`
  z-index: 2;
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
