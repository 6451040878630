import React from "react";
import styled from "styled-components";

export const NavBar = (props: { children: React.ReactNode }) => {
  return <Navbar>{props.children}</Navbar>;
};

const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 60%;
`;
