import { NFT } from "../types/nfts";

const DROIDBGS = ["DROID FARM BLUE", "DROID FARM BLACK", "DROID FARM PURPLE", "DROID FARM"];
const TIGERCLAWBGS = ["TIGER CLAW WHITE", "TIGER CLAW YELLOW", "TIGER CLAW", "TIGER CLAW RED", "PREACHER"];
const BACKYARDBGS = [
  "BACKYARD BRAWLING BROWN",
  "BACKYARD BRAWLERS ORANGE",
  "BACKYARD BRAWLERS GREEN",
  "BACKYARD BRAWLERS BROWN",
  "BACKYARD BRAWLING GREEN",
  "BACKYARD BRAWLING ORANGE",
  "BACKYARD BRAWLING",
  "BACKYARD BRAWLERS",
  "TATAKAE",
];

export const capitalizeText = (str: string) => {
  const lower = str.toLowerCase();
  return lower.slice(0, 1).toUpperCase() + lower.slice(1, str.length);
};

export const determineGender = (name?: string) => {
  if (!name) {
    return "M";
  }
  try {
    if (parseInt(name.replace(/[#A-z\s]+/, "")) >= 1111) {
      return "F";
    } else {
      return "M";
    }
  } catch {
    return "M";
  }
};

/**
 * 0 = BACKYARD
 * 1 = DROID
 * 2 = TIGER
 */
export const determineFaction = (nft?: NFT) => {
  if (nft) {
    const bg = nft.metadata.attributes[0].value;
    if (BACKYARDBGS.includes(bg)) {
      return 0;
    } else if (DROIDBGS.includes(bg)) {
      return 1;
    } else if (TIGERCLAWBGS.includes(bg)) {
      return 2;
    }
  }
  return 0;
};

export const randomChoice = <T>(array: Array<T>): T => {
  return array[Math.floor(Math.random() * array.length)];
};

export const sleep = (ms: number) => {
  return new Promise((res) => setTimeout(res, ms));
};

export const clamp = (min: number, max: number, value: number) => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};
