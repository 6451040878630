/**
 * Returns the value of the trait based off the category
 *
 * locateTrait('Fists', attributes) => "GLOVES RED"
 */
export const locateTrait = (category: string, attributes: { trait_type: string; value: string }[]) => {
  for (const attr of attributes) {
    if (attr.trait_type === category) {
      return attr.value;
    }
  }
  return null;
};
