import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter,
  WalletConnectWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Leaderboard } from "./pages/Leaderboard";
import MainMenu from "./pages/MainMenu";
import { OneVOneSelect } from "./pages/OneVOneSelect";
import { QuickFight } from "./pages/QuickFight";
import { QuickFightMain } from "./pages/QuickFightMain";

export default function App() {
  const endpoint = "https://moll-t6hquo-fast-mainnet.helius-rpc.com/";

  const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
    new SolletWalletAdapter(),
    new SlopeWalletAdapter(),
    new WalletConnectWalletAdapter({
      network: WalletAdapterNetwork.Mainnet,
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        // Seems to work without setting up a project
        projectId: "e899c82be21d4acca2c8aec45e893598",
        metadata: {
          name: "The Sol Den",
          description: "Bring your fighters to the ring and fight for glory!",
          url: "https://fight.solden.io/",
          icons: ["https://upgrade.solden.io/logo.png"],
        },
      },
    }),
  ];

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainMenu />} />
              <Route path="/quickfight" element={<QuickFight />} />
              <Route path="/onevone" element={<OneVOneSelect />} />
              <Route path="/fight" element={<QuickFightMain />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
            </Routes>
          </BrowserRouter>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
