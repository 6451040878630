//@ts-nocheck

import FFL from "url:../assets/quickfight/ENDVIDEOS/FF-L.mp4";
import FFW from "url:../assets/quickfight/ENDVIDEOS/FF-W.mp4";
import FML from "url:../assets/quickfight/ENDVIDEOS/FM-L.mp4";
import FMW from "url:../assets/quickfight/ENDVIDEOS/FM-W.mp4";
import MFL from "url:../assets/quickfight/ENDVIDEOS/MF-L.mp4";
import MFW from "url:../assets/quickfight/ENDVIDEOS/MF-W.mp4";
import MML from "url:../assets/quickfight/ENDVIDEOS/MM-L.mp4";
import MMW from "url:../assets/quickfight/ENDVIDEOS/MM-W.mp4";
import FFF1 from "url:../assets/quickfight/GIFS/FF-F1.gif";
import FFF2 from "url:../assets/quickfight/GIFS/FF-F2.gif";
import FFF3 from "url:../assets/quickfight/GIFS/FF-F3.gif";
import FFF4 from "url:../assets/quickfight/GIFS/FF-F4.gif";
import FFF5 from "url:../assets/quickfight/GIFS/FF-F5.gif";
import FFF6 from "url:../assets/quickfight/GIFS/FF-F6.gif";
import FFIdle from "url:../assets/quickfight/GIFS/FF-IDLE.gif";
import FMF1 from "url:../assets/quickfight/GIFS/FM-F1.gif";
import FMF2 from "url:../assets/quickfight/GIFS/FM-F2.gif";
import FMF3 from "url:../assets/quickfight/GIFS/FM-F3.gif";
import FMF4 from "url:../assets/quickfight/GIFS/FM-F4.gif";
import FMF5 from "url:../assets/quickfight/GIFS/FM-F5.gif";
import FMF6 from "url:../assets/quickfight/GIFS/FM-F6.gif";
import FMIdle from "url:../assets/quickfight/GIFS/FM-IDLE.gif";
import MFF1 from "url:../assets/quickfight/GIFS/MF-F1.gif";
import MFF2 from "url:../assets/quickfight/GIFS/MF-F2.gif";
import MFF3 from "url:../assets/quickfight/GIFS/MF-F3.gif";
import MFF4 from "url:../assets/quickfight/GIFS/MF-F4.gif";
import MFF5 from "url:../assets/quickfight/GIFS/MF-F5.gif";
import MFF6 from "url:../assets/quickfight/GIFS/MF-F6.gif";
import MFIdle from "url:../assets/quickfight/GIFS/MF-IDLE.gif";
import MMF1 from "url:../assets/quickfight/GIFS/MM-F1.gif";
import MMF2 from "url:../assets/quickfight/GIFS/MM-F2.gif";
import MMF3 from "url:../assets/quickfight/GIFS/MM-F3.gif";
import MMF4 from "url:../assets/quickfight/GIFS/MM-F4.gif";
import MMF5 from "url:../assets/quickfight/GIFS/MM-F5.gif";
import MMIdle from "url:../assets/quickfight/GIFS/MM-IDLE.gif";
import FFF1_FX from "url:../assets/quickfight/SoundFX/FFF1.mp3";
import FFF2_FX from "url:../assets/quickfight/SoundFX/FFF2.mp3";
import FFF3_FX from "url:../assets/quickfight/SoundFX/FFF3.mp3";
import FFF4_FX from "url:../assets/quickfight/SoundFX/FFF4.mp3";
import FFF5_FX from "url:../assets/quickfight/SoundFX/FFF5.mp3";
import FFF6_FX from "url:../assets/quickfight/SoundFX/FFF6.mp3";
import FMF1_FX from "url:../assets/quickfight/SoundFX/FMF1.mp3";
import FMF2_FX from "url:../assets/quickfight/SoundFX/FMF2.mp3";
import FMF3_FX from "url:../assets/quickfight/SoundFX/FMF3.mp3";
import FMF4_FX from "url:../assets/quickfight/SoundFX/FMF4.mp3";
import FMF5_FX from "url:../assets/quickfight/SoundFX/FMF5.mp3";
import FMF6_FX from "url:../assets/quickfight/SoundFX/FMF6.mp3";
import MFF1_FX from "url:../assets/quickfight/SoundFX/MFF1.mp3";
import MFF2_FX from "url:../assets/quickfight/SoundFX/MFF2.mp3";
import MFF3_FX from "url:../assets/quickfight/SoundFX/MFF3.mp3";
import MFF4_FX from "url:../assets/quickfight/SoundFX/MFF4.mp3";
import MFF5_FX from "url:../assets/quickfight/SoundFX/MFF5.mp3";
import MFF6_FX from "url:../assets/quickfight/SoundFX/MFF6.mp3";
import MMF1_FX from "url:../assets/quickfight/SoundFX/MMF1.mp3";
import MMF2_FX from "url:../assets/quickfight/SoundFX/MMF2.mp3";
import MMF3_FX from "url:../assets/quickfight/SoundFX/MMF3.mp3";
import MMF4_FX from "url:../assets/quickfight/SoundFX/MMF4.mp3";
import MMF5_FX from "url:../assets/quickfight/SoundFX/MMF5.mp3";

//Animation GIFs
export const AnimGIFs: { [gendersCode: string]: { idle: any; fight: any[]; fx: any[]; end: { W: any; L: any } } } = {
  MM: {
    fight: [MMF1, MMF2, MMF3, MMF4, MMF5],
    idle: MMIdle,
    fx: [MMF1_FX, MMF2_FX, MMF3_FX, MMF4_FX, MMF5_FX],
    end: {
      W: MMW,
      L: MML,
    },
  },
  MF: {
    fight: [MFF1, MFF2, MFF3, MFF4, MFF5, MFF6],
    idle: MFIdle,
    fx: [MFF1_FX, MFF2_FX, MFF3_FX, MFF4_FX, MFF5_FX, MFF6_FX],
    end: {
      W: MFW,
      L: MFL,
    },
  },
  FF: {
    fight: [FFF1, FFF2, FFF3, FFF4, FFF5, FFF6],
    idle: FFIdle,
    fx: [FFF1_FX, FFF2_FX, FFF3_FX, FFF4_FX, FFF5_FX, FFF6_FX],
    end: {
      W: FFW,
      L: FFL,
    },
  },
  FM: {
    fight: [FMF1, FMF2, FMF3, FMF4, FMF5, FMF6],
    idle: FMIdle,
    fx: [FMF1_FX, FMF2_FX, FMF3_FX, FMF4_FX, FMF5_FX, FMF6_FX],
    end: {
      W: FMW,
      L: FML,
    },
  },
};
