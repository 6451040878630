import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useMemo } from "react";
import React from "react";
import styled from "styled-components";
//@ts-ignore
import BGVideo from "url:../assets/videos/mainmenu.mp4";

import { CenteredContainer } from "../components/CenteredContainer";
import { NavBar } from "../components/NavBar";
import { BREAKPOINT } from "../constants/constants";
import { useLeaderboard } from "../hooks/useLeaderboard";
import { useNFTs } from "../hooks/useNFTs";
import { LeaderboardNFT } from "../types/leaderboard";
import { MenuHeader } from "./MainMenu";
import { BackButton, LoadingText } from "./QuickFight";

const DFMusic = require("url:../assets/quickfight/BGMusic/DFMusic.mp3");

export const Leaderboard = () => {
  const wallet = useWallet();
  const nfts = useNFTs();
  const leaderboard = useLeaderboard();

  const entryExistsInBoard = (board: LeaderboardNFT[], mint: string) => {
    for (const entry of board) {
      if (entry.mint === mint) {
        return entry;
      }
    }
    return null;
  };

  const top10PlusUser = useMemo(() => {
    if (leaderboard.length > 0) {
      let top10 = leaderboard.slice(0, 10);
      for (const nft of nfts) {
        const lbEntry = entryExistsInBoard(leaderboard, nft.mint);
        if (!entryExistsInBoard(top10, nft.mint) && lbEntry) {
          top10.push(lbEntry);
        }
      }
      top10 = top10.sort((a, b) => a.rank - b.rank);
      return top10.map((v, i) => {
        return (
          <LBRow key={i}>
            <td>
              <NFTImage src={v.metadata.image} />
            </td>
            <td>
              <TableText>{v.metadata.name}</TableText>
            </td>
            <td>
              <TableText style={{ color: "#4fb846" }}>{v.rank + 1}</TableText>
            </td>
            <td>
              <TableText style={{ color: "#50b2bf" }}>{v.dispWins}</TableText>
            </td>
            <td>
              <TableText style={{ color: "#d43f3f" }}>{v.dispLosses}</TableText>
            </td>
          </LBRow>
        );
      });
    }
    return [];
  }, [leaderboard, nfts, wallet]);

  return (
    <>
      <video className="bg-video" src={BGVideo} autoPlay={true} loop={true} muted={true} />
      <audio src={DFMusic} autoPlay loop />
      <CenteredContainer style={{ height: "100%" }}>
        <CenteredContainer style={{ width: "100%" }}>
          <NavBar>
            <BackButton onClick={() => (window.location.href = "/")}>GO BACK</BackButton>
            <WalletMultiButton />
          </NavBar>
        </CenteredContainer>
        <MenuHeader style={{ marginTop: "50px", padding: 0 }}>LEADERBOARD</MenuHeader>
        {!wallet.connected && <LoadingText>Connect your wallet to see your fighters&apos; ranks!</LoadingText>}
        {top10PlusUser.length > 0 && (
          <LeaderboardTable>
            <LBRow>
              <TableHead>NFT</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Rank</TableHead>
              <TableHead>Wins</TableHead>
              <TableHead>Losses</TableHead>
            </LBRow>
            {top10PlusUser}
          </LeaderboardTable>
        )}
      </CenteredContainer>
    </>
  );
};

const LeaderboardTable = styled.table`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin-top: 50px;
  width: 80%;
  border-radius: 10px;
  &:hover {
    -webkit-box-shadow: 0px 0px 20px 0px #ff914d;
    -moz-box-shadow: 0px 0px 20px 0px #ff914d;
    box-shadow: 0px 0px 20px 0px #ff914d;
    transition: 0.8s ease;
  }
  td {
    text-align: center;
  }
`;

const TableText = styled.span`
  font-family: Lovelo;
  font-size: 2rem;
  color: white;
  ${BREAKPOINT} {
    font-size: 1rem;
  }
`;

const TableHead = styled.th`
  font-family: Lovelo;
  font-size: 2rem;
  color: white;
  ${BREAKPOINT} {
    font-size: 1rem;
  }
`;

const NFTImage = styled.img`
  width: 100px;
  border-radius: 50%;
  ${BREAKPOINT} {
    width: 50px;
  }
`;

const LBRow = styled.tr``;
