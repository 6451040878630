import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import styled from "styled-components";
//@ts-ignore
import BGVideo from "url:../assets/videos/mainmenu.mp4";

import { CenteredContainer } from "../components/CenteredContainer";
import { MenuButton } from "../components/MenuButton";
import { NavBar } from "../components/NavBar";
import { GameScreen } from "../components/Screen";
import { API_ENDPOINT, BREAKPOINT } from "../constants/constants";
import { Stats, useStatsBoosts } from "../hooks/useStatsBoosts";
import { NFT } from "../types/nfts";
import { StatefulContext } from "../types/react";
import { calculateFighterStats } from "../utils/calculateFighterStats";
import { locateTrait } from "../utils/locateTrait";

const DFMusic = require("url:../assets/quickfight/BGMusic/DFMusic.mp3");

const FighterStateContext = createContext<StatefulContext<NFT | undefined>>(null);
const MenuStateContext = createContext<StatefulContext<number>>(null);

export const QuickFight = () => {
  const wallet = useWallet();
  const [menuMode, setMenuMode] = useState(0);
  const [selectedFighter, setSelectedFighter] = useState<NFT>();

  const handleBackButton = () => {
    if (menuMode === 0) {
      window.location.href = "/";
    } else {
      setMenuMode(menuMode - 1);
    }
  };

  return (
    <MenuStateContext.Provider value={[menuMode, setMenuMode]}>
      <FighterStateContext.Provider value={[selectedFighter, setSelectedFighter]}>
        <GameScreen>
          <video className="bg-video" src={BGVideo} autoPlay={true} loop={true} muted={true} />
          <audio src={DFMusic} autoPlay loop />
          <CenteredContainer>
            <NavBar>
              <BackButton onClick={handleBackButton}>GO BACK</BackButton>
              <WalletMultiButton />
            </NavBar>
          </CenteredContainer>
          <CenteredContainer>
            {menuMode === 0 && (
              <>
                <MenuHeader>{wallet.publicKey ? "SELECT YOUR FIGHTER" : "CONNECT YOUR WALLET"}</MenuHeader>
                {wallet.publicKey && <SelectFighterGrid />}
              </>
            )}
            {menuMode === 1 && <FighterStats nft={selectedFighter!} />}
          </CenteredContainer>
        </GameScreen>
      </FighterStateContext.Provider>
    </MenuStateContext.Provider>
  );
};

const SelectFighterGrid = () => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchNFTs = async () => {
      const hashList = (
        await connection.getParsedTokenAccountsByOwner(wallet.publicKey!, { programId: TOKEN_PROGRAM_ID }, "confirmed")
      ).value
        .filter((v) => v.account.data.parsed.info.tokenAmount.uiAmount > 0)
        .map((v) => v.account.data.parsed.info.mint);
      const metadata: NFT[] = await (
        await fetch(`${API_ENDPOINT}/getmetadata`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: hashList }),
        })
      ).json();
      setNfts(metadata);
    };
    if (!wallet.publicKey) {
      setNfts([]);
      return;
    }
    if (wallet.publicKey) {
      fetchNFTs()
        .then(() => setLoaded(true))
        .catch(() => {
          setLoaded(true);
          setNfts([]);
        });
    }
  }, [wallet.publicKey]);

  return (
    <>
      {nfts.length === 0 && (
        <LoadingText>{loaded ? "No available fighters found!" : "Loading your Fighters..."}</LoadingText>
      )}
      {nfts.length > 0 && (
        <FighterGrid>
          {nfts.map((v, i) => {
            return (
              <>
                <FighterSelectionCard key={i} nft={v} />
              </>
            );
          })}
        </FighterGrid>
      )}
    </>
  );
};

const FighterSelectionCard = (props: { nft: NFT }) => {
  const [, setFighter] = useContext(FighterStateContext)!;
  const [, setMenuMode] = useContext(MenuStateContext)!;

  return (
    <SelectionCard>
      <SelectionCardImage
        onClick={() => {
          setMenuMode(1);
          setFighter(props.nft);
        }}
        src={props.nft.metadata.image}
      />
    </SelectionCard>
  );
};

const FighterStats = (props: { nft: NFT }) => {
  const boosts = useStatsBoosts();
  const edition = parseInt(props.nft.metadata.name.replace(/[#A-z\s]+/, ""));
  const [sf] = useContext(FighterStateContext)!;
  const [stats, setStats] = useState<Stats>({
    agility: 10,
    attack: 15,
    defense: 2,
  });

  const fistsAttr = locateTrait("Fists", props.nft.metadata.attributes);
  const gumguardAttr = locateTrait("Gum Guards", props.nft.metadata.attributes);
  const pantsAttr = locateTrait("Pants", props.nft.metadata.attributes);

  useEffect(() => {
    if (props.nft.metadata) {
      setStats(calculateFighterStats(props.nft, boosts));
    }
  }, [sf, boosts]);

  const wins = props.nft?.metadata?.attributes?.find((v) => v.trait_type === "W")?.value;
  const losses = props.nft?.metadata?.attributes?.find((v) => v.trait_type === "L")?.value;

  return (
    <>
      <MenuHeader>NFT #{edition}</MenuHeader>
      <div style={{ display: "flex" }}>
        <StatsPanel>
          <StatsImgContainer>
            <img src={props.nft.metadata.image} style={{ width: "100%" }} />
          </StatsImgContainer>
          <StatText color="#ff914d">Damage: {stats.attack}</StatText>
          <StatText color="#38B6FF">Defense: {stats.defense}</StatText>
          <StatText color="#C9E265">Agility: {stats.agility}</StatText>
          <StatText color="white">
            Wins: {wins} Losses: {losses}
          </StatText>
        </StatsPanel>
        <div style={{ padding: "20px", width: "100%" }}>
          <TraitPanel>
            <TraitPanelTitle>TRAITS</TraitPanelTitle>
            <TraitInfo traitName="Fists" value={fistsAttr!} boost={stats.attack - 15} boostStat="Damage" />
            <TraitInfo traitName="Gum Guards" value={gumguardAttr!} boost={stats.defense - 2} boostStat="Defense" />
            <TraitInfo traitName="Pants" value={pantsAttr!} boost={stats.agility - 10} boostStat="Agility" />
          </TraitPanel>
        </div>
      </div>
      <div style={{ position: "fixed", bottom: 0 }}>
        <MenuButton onClick={() => (window.location.href = `/fight?with=${props.nft.mint}`)}>FIGHT</MenuButton>
      </div>
    </>
  );
};

const TraitInfo = (props: { traitName: string; value: string; boost: number; boostStat: string }) => {
  return (
    <>
      <TraitName>{props.traitName}</TraitName>
      <TraitValue>
        {props.value.toLowerCase()} <br />
        <BoostText
          style={{
            color: (() => {
              switch (props.boostStat) {
                case "Damage":
                  return "#ff914d";
                case "Defense":
                  return "#38B6FF";
                case "Agility":
                  return "#C9E265";
              }
            })(),
          }}
        >
          (+{props.boost} {props.boostStat})
        </BoostText>
      </TraitValue>
    </>
  );
};

const TraitPanel = styled.div`
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.8s ease;
  &:hover {
    -webkit-box-shadow: 0px 0px 20px 0px #ff914d;
    -moz-box-shadow: 0px 0px 20px 0px #ff914d;
    box-shadow: 0px 0px 20px 0px #ff914d;
    transition: 0.8s ease;
  }
  ${BREAKPOINT} {
    width: 100%;
  }
`;

const BoostText = styled.span`
  font-family: CooperHewittHeavy;
  font-size: 1.25rem;
  color: white;
  text-align: center;
  text-transform: capitalize;
`;

const TraitValue = styled.span`
  font-family: CooperHewittHeavy;
  font-size: 2rem;
  font-weight: lighter;
  color: white;
  margin-bottom: 40px;
  text-align: center;
  text-transform: capitalize;
  ${BREAKPOINT} {
    font-size: 1rem;
  }
`;

const TraitName = styled.span`
  font-family: CaptureIt;
  color: white;
  font-size: 2.5rem;
  margin-bottom: 2px;
  ${BREAKPOINT} {
    font-size: 1.5rem;
  }
`;

const TraitPanelTitle = styled.span`
  font-family: CooperHewittHeavy;
  color: white;
  font-size: 4rem;
  margin-bottom: 30px;
  -webkit-text-stroke: 3px white;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  ${BREAKPOINT} {
    font-size: 2rem;
  }
`;

const StatsPanel = styled.div`
  max-width: 50%;
  padding: 20px 20px 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${BREAKPOINT} {
    padding-left: 10px;
  }
`;

const StatsImgContainer = styled.div`
  background-color: #ff914d69;
  border-radius: 1rem;
  padding: 20px;
  width: 50%;
  img {
    border-radius: 1rem;
  }
  ${BREAKPOINT} {
    padding: 8px;
  }
`;

const StatText = styled.span`
  margin-top: 10px;
  width: 50%;
  font-family: CooperHewittHeavy;
  font-size: 2.5rem;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${(props) => props.color};
  ${BREAKPOINT} {
    font-size: 1rem;
  }
`;

const SelectionCard = styled(CenteredContainer)`
  width: 100%;
`;

const SelectionCardImage = styled.img`
  width: 100%;
  transition: 200ms;
  border-radius: 4px;
  &:hover {
    filter: brightness(50%);
    cursor: pointer;
  }
`;

export const BackButton = styled.button`
  margin-left: 20px;
  border: none;
  background: none;
  font-size: 2rem;
  font-family: CooperHewittHeavy;
  letter-spacing: 3px;
  -webkit-text-stroke: 1px var(--menuTextColor);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
  transition: 200ms;

  &:disabled {
    -webkit-text-stroke: 2px var(--disabledOptionColor);
  }
  &:hover {
    cursor: pointer;
    letter-spacing: 6px;
    -webkit-text-stroke: 0px var(--menuTextColor);
    -webkit-text-fill-color: var(--menuTextColor);
  }
  &:disabled:hover {
    cursor: default;
    -webkit-text-stroke: 2px var(--disabledOptionColor);
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
  }

  ${BREAKPOINT} {
    font-size: 1rem;
  }
`;

export const LoadingText = styled.span`
  color: white;
  text-align: center;
  font-family: CaptureIt;
  font-size: 3rem;
  width: 100%;
  margin-top: 50px;
`;

const MenuHeader = styled.span`
  border: none;
  background: none;
  font-size: 4rem;
  font-family: CooperHewittHeavy;
  -webkit-text-stroke: 3px var(--menuTextColor);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  letter-spacing: 3px;
  color: white;
  filter: drop-shadow(16px 16px 10px black);
  text-align: center;
  ${BREAKPOINT} {
    margin-top: 50px;
    font-size: 2rem;
  }
`;

const FighterGrid = styled.div`
  padding: 25px;
  max-width: 80%;
  background-color: #ff914d69;
  border-radius: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 50px;
  ${BREAKPOINT} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
