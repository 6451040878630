import React, { RefObject } from "react";
import styled from "styled-components";

export const MenuButton = (props: {
  children: React.ReactNode;
  onClick?: () => void;
  refVar?: RefObject<HTMLButtonElement>;
  disabled?: boolean;
  overrides?: object;
}) => {
  return (
    <Button
      className="menu-button"
      style={props.overrides ? props.overrides : {}}
      ref={props.refVar}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

const Button = styled.button`
  border: none;
  background: none;
  font-size: 4rem;
  font-family: CooperHewittHeavy;
  letter-spacing: 3px;
  -webkit-text-stroke: 3px var(--menuTextColor);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
  transition: 200ms;

  &:disabled {
    -webkit-text-stroke: 2px var(--disabledOptionColor);
  }
  &:hover {
    cursor: pointer;
    letter-spacing: 6px;
    -webkit-text-stroke: 0px var(--menuTextColor);
    -webkit-text-fill-color: var(--menuTextColor);
  }
  &:disabled:hover {
    cursor: default;
    -webkit-text-stroke: 2px var(--disabledOptionColor);
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;
