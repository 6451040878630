import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";

import { API_ENDPOINT } from "../constants/constants";
import { NFT } from "../types/nfts";

export const useNFTs = () => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [nfts, setNfts] = useState<NFT[]>([]);

  useEffect(() => {
    const fetchNFTs = async () => {
      const hashList = (
        await connection.getParsedTokenAccountsByOwner(wallet.publicKey!, { programId: TOKEN_PROGRAM_ID }, "confirmed")
      ).value
        .filter((v) => v.account.data.parsed.info.tokenAmount.uiAmount > 0)
        .map((v) => v.account.data.parsed.info.mint);
      const metadata: NFT[] = await (
        await fetch(`${API_ENDPOINT}/getmetadata`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: hashList, override: true }),
        })
      ).json();
      setNfts(metadata);
    };
    if (!wallet.connected) {
      setNfts([]);
      return;
    }
    fetchNFTs();
  }, [wallet.publicKey]);

  return nfts;
};
